import { render, staticRenderFns } from "./AddRewardCategory.vue?vue&type=template&id=510e160f"
import script from "./AddRewardCategory.vue?vue&type=script&lang=js"
export * from "./AddRewardCategory.vue?vue&type=script&lang=js"
import style0 from "./AddRewardCategory.vue?vue&type=style&index=0&id=510e160f&prod&lang=css"
import style1 from "./AddRewardCategory.vue?vue&type=style&index=1&id=510e160f&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports